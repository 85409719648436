// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import GlossaryLink from '../../components/glossary-link';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';
import CanyonRatingCard from '../../components/canyon-rating-card';
import AllImagesLink from '../../components/all-images-link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _1: ImageType,
    _2: ImageType,
    _3: ImageType,
    _4: ImageType,
    _5: ImageType,
    _6: ImageType,
    _7: ImageType,
    _8: ImageType,
    _9: ImageType,
    _10: ImageType,
    _11: ImageType,
  },
};

class Blog_2018_03_03_No_Kidding extends React.PureComponent<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <CanyonRatingCard rating="4ARIII" title="No Kidding" />
        <p>
          Great <GlossaryLink>sand trap</GlossaryLink> practice in No Kidding
          Canyon.
        </p>
        <p>
          No Kidding is such a fun canyon. It is unique among its Irish and
          Butler Wash neighbors because of its long drops and{' '}
          <GlossaryLink>potholes</GlossaryLink>. Combining this with the fact
          that there is no <GlossaryLink>natural anchor</GlossaryLink> material
          outside of sand, it makes for the perfect sand trap canyon. Must be
          why it's the most famous.
        </p>
        <Image caption="Setting up the first sand trap" image={data._2} />
        <Image caption="Some pre-rappel instruction" image={data._3} />
        <Image caption="Smile for the camera" image={data._4} />
        <Image caption="Gently on rappel" image={data._5} />
        <p>
          The hours went by efficiently. We had a longer rope so we strung
          several drops together from one anchor and were able to save time
          building anchors. If I didn't have Sandthrax in the back of my mind
          the whole time I would have insisted on rigging more rappels so we
          could practice building anchors. Oh well, next time.
        </p>
        <Image image={data._6} />
        <Image
          caption="I'll get around to explaining what is going on here some day"
          image={data._7}
        />
        <Image image={data._8} />
        <Image
          caption="I'll get around to explaining what is going on here some day"
          image={data._9}
        />
        <p>
          We eventually made it to the last rappel which was rigged with a{' '}
          <GlossaryLink>toggle</GlossaryLink> around a shallow horn. With a
          little mindfulness it was solid for every rappeller. My time as{' '}
          <GlossaryLink>LAPAR</GlossaryLink> came and it held perfectly for me
          as well. A purposeful pull, pleasing pop, and down came the rigging -
          and we were out.
        </p>
        <AllImagesLink id={frontMatter.id} />
      </Layout>
    );
  }
}

export default withFrontMatter('2018-03-03-no-kidding')(
  Blog_2018_03_03_No_Kidding
);

export const query = graphql`
  query {
    _1: file(
      relativePath: {
        eq: "images/blog/2018-03-03-no-kidding/2018_03_03_No_Kidding-1.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _2: file(
      relativePath: {
        eq: "images/blog/2018-03-03-no-kidding/2018_03_03_No_Kidding-2.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _3: file(
      relativePath: {
        eq: "images/blog/2018-03-03-no-kidding/2018_03_03_No_Kidding-3.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _4: file(
      relativePath: {
        eq: "images/blog/2018-03-03-no-kidding/2018_03_03_No_Kidding-4.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _5: file(
      relativePath: {
        eq: "images/blog/2018-03-03-no-kidding/2018_03_03_No_Kidding-5.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _6: file(
      relativePath: {
        eq: "images/blog/2018-03-03-no-kidding/2018_03_03_No_Kidding-6.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _7: file(
      relativePath: {
        eq: "images/blog/2018-03-03-no-kidding/2018_03_03_No_Kidding-7.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _8: file(
      relativePath: {
        eq: "images/blog/2018-03-03-no-kidding/2018_03_03_No_Kidding-8.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _9: file(
      relativePath: {
        eq: "images/blog/2018-03-03-no-kidding/2018_03_03_No_Kidding-9.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
